
.leave-form1 .btn.custom-btn {
    background-color: #E3B075;
    border-color: #E3B075;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    /* padding: .75rem 2.2rem; */
    cursor: pointer;
  }
  
  .leave-form1 .btn.custom-btn:hover {
    background-color: #f5a623;
    border-color: #f5a623;
  }