.physician-box{
    border: 3px solid #FFF;
    border-radius:10px;
    background-image: linear-gradient(to right, #FFDBB0,#FFF4E6);
}
.tabg1 tr td{
padding-top: 1rem;
background-color: #fffaf4;
}

.tabg1 tr td:last-child{
    padding-top: .5rem;
}
.object-fit img {
    object-fit: fill;
    height: 100%;
    border-radius: 50%; 
}

.object-fit{
    width: 3rem;
    height: 3rem;
}

