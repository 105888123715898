.popup-btn{
background-color: #eeeeee;
padding: 1rem;
text-align: center;
cursor: pointer;
color: black;
border:3px solid #fafafa;
}

.activebtn{
    background-color: #E3B075;
}

.adm-popup-height{
    height: 46rem;
    padding: .625rem;
}