
.item_add {
  background-color:white;
}

.staff-table-cont{
    /* width:85rem !important; */
    height: 25.8rem;
    overflow: hidden !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 10px;
    min-width: 100%;
    /* max-width: 100%; */
    height: 24.8rem;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 0;
}

.StaffTable thead th:first-child{
    color: black;
    width:25rem !important;
    height: 2rem !important;
    width:25rem !important;
    height: 2rem !important;
    position: sticky;
    left:0;
    z-index: 2;
}

.StaffTable tbody td:first-child{
    position: sticky;
    left:0;
    z-index: 1;
    background-color: #EEEEEE  ;
    color:rgb(0, 0, 0) !important;
}

.StaffTable thead tr th {
    background-color: #FBD9B3  ;
    width: 3.5rem;
    height: 3.5rem;
    border: .5px solid #c2c2c2;
    color:rgb(0, 0, 0) !important;
    text-align: center;
}

.StaffTable tbody tr td {
    width: 3.5rem;
    height: 3.5rem;
    border: .5px solid #c2c2c2;
    /* box-shadow: 0 0 0 0.5px black; */
    padding-left: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
}

.StaffTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: .25rem;
}

.StaffTable thead tr th{
    position: sticky;
    z-index: 4;
    top: 0;
}

.staffModal{
    width: 30rem;
    height: fit-content;
}

.staffModal2{
    width: 30rem;
    height: fit-content;
}
.staffModal2{
    width: 30rem;
    height: fit-content;
}


/*150*/
@media (min-height: 450px){
    .zoom-responsive-view-attendance{
      zoom: .55;
    }
    .attend-modal{
        zoom: .65;
    }
  }
  /*133*/
  @media (min-height: 500px){
    .zoom-responsive-view-attendance{
      zoom: .61
    }
    .attend-modal{
        zoom: .7;
    }
  }
  /*120*/
  @media (min-height: 600px){
    .zoom-responsive-view-attendance{
      zoom: .675;
    }
    .attend-modal{
        zoom: .75;
    }
  }
  /* 110 */
  @media (min-height: 650px){
    .zoom-responsive-view-attendance{
      zoom: .75;
    }
    .attend-modal{
        zoom: .85;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .zoom-responsive-view-attendance{
    zoom: .82;
    }
    .attend-modal{
        zoom: .9;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .zoom-responsive-view-attendance{
      zoom: .91;
    }
    .attend-modal{
        zoom: 1;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .zoom-responsive-view-attendance{
      /* height: 43rem; */
      zoom: .95;
    }
    .attend-modal{
        zoom: 1.2;
    }
  }
  
  /*70*/
  @media (min-height: 1000px){
    .zoom-responsive-view-attendance{
      zoom: 1.2;
    }
    .attend-modal{
        zoom: 1.4;
    }
  }