
 .salary-date{
    color: rgb(117, 113, 113);
 }
.table-payroll-report-head tr th{
    /* width: 20%; */
    padding: 5px;
    background-color: #FBD9B3;;
}
.calendar-img{
    position: absolute;
    right: 3px;
}
.vector-icon{
    cursor: pointer;
}