.container  .medicine{
    max-width: 100%; 
    overflow-x: auto; 
    overflow-y: scroll;
  }
  
  .medicine table {
    width: 100%; 
    table-layout: fixed; 
  }
  
  .medicine th{
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
    word-wrap: break-word; 
  }
  .medicine td {
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
  
  }
  
  .medicine th:last-child, .medicine th:first-child,.medicine td:last-child, .medicine td:first-child {
    width: 4rem;
  }
  
  .medicine .custom_input1 {
    max-width: 50px; 
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee;  
    padding: 10px;
  }

  .medicine .custom_input {
    padding: 16px;
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee;  
  }

  .medicine th:nth-child(2) , .medicine th:nth-child(9){
    width:15rem;
    min-width:15rem;
    max-width:15rem
  }
  

  .container .treatment {
    max-width: 100%; 
    overflow-x: auto; 
    overflow-y: scroll;
  }
  
 .treatment table {
    width: 100%; 
    table-layout: fixed;  
  }
  
  .treatment th {
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
  }
  .treatment td {
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
    word-wrap: break-word; 
  }
  
  .treatment th:last-child,  .treatment th:first-child, .treatment td:last-child, .treatment td:first-child {
    width: 4rem;
  }
  
  .treatment .custom_input1 {
    max-width: 50px; 
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee;  
    padding: 10px;
  }

  .treatment .custom_input {
    padding: 16px;
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee; 
    overflow: hidden; 
  }

  .treatment th:nth-child(2) , .treatment th:nth-child(5), .treatment td:nth-child(2) , .treatment td:nth-child(5){
    width:25rem;
    min-width:25rem;
    max-width:25rem;
  }
  
  .treatmentbottomborder{
    border-bottom: 4px solid #e3b075 !important;
  }