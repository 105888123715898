/* CheckBox Button Styles start */
/* .input-checkbox-control{
    position: relative;
    border-radius: 0.3125rem !important;
    border: 1px solid #D0D0D0;
    height: 2.09863rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.input-checkbox-control .focus{
    border-color: #154674 !important;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25) !important;
}

.input-checkbox-control.active{
    background-color: #E3B075 ;
    color: #fff;
} */
/* 
.input-checkbox-control .slider-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -2;
}

.input-checkbox-control .text {
    transition: all 1s;
    color: #5D5D5D;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
}

.input-checkbox-control .slide {
    background: #154674;
    position: absolute;
    transition: all 1s;
    height: 200px;
    border-radius: 50%;
    width: 200px;
    z-index: -1;
    top: -210px;
    left: 40px;
}

.input-checkbox-control.active .slide {
    top:-80px;
    left: -40px;
    transform: rotate(0deg);
}

.input-checkbox-control.active .text {
    color: #E3B075;
} */

/* CheckBox Button Styles end */


.input-checkbox-control{
    position: relative;
    border-radius: 0.3125rem !important;
    border: 1px solid #D0D0D0;
    height: 2.09863rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff
}

.input-checkbox-control .focus{
    border-color: #E3B075 !important;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.1rem rgba(40, 95, 167, 0.25) !important;
}

.input-checkbox-control.active{
    background: linear-gradient(to right, #E3B075, #fbdf96); /* Active gradient */
    color: #fff;
}
