.total-therapy-bed {
    width: 17.2rem;
    height: 130px;
    border: 1px solid rgb(12, 12, 12);
    border-radius: 10px 0px 0px 0px;
    opacity: 0px;
    box-shadow: 5px;
    border: 1px solid white;
    box-shadow: 0px 0px 6px rgba(60, 59, 59, 0.1);
    background-color: #fff;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
  }
  .supported-therapy{
    border: 1px solid white;
    box-shadow: 0px 0px 6px rgba(60, 59, 59, 0.1);
    background-color: #FDFDFD;
    border-radius: 9px;

  }
  .supported-therapy-inner{
    background-color: #FFF6EC;
    border-radius: 12px;

  }
.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 2.5rem;
  height: 2.5rem;
  border: 1.5px solid #E3B075;
  background-color: white;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  border-color:  #E3B075;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 12px;
  width: 6px;
  height: 12px;
  border: solid #E3B075;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
