  .leave-form .form-control,.leave-form .form-select {
    height: 3.17rem;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #f7f7f7;
    border-radius: 6px;
    font-weight: 400;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    font-size: medium;
  }
  
  .leave-form label {
    font-weight: bold;
    font-size: 1rem;
  }
  
  .leave-form .btn.custom-btn {
    background-color: #E3B075;
    border-color: #E3B075;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: .75rem 2.2rem;
    cursor: pointer;
  }
  
  .leave-form .btn.custom-btn:hover {
    background-color: #f5a623;
    border-color: #f5a623;
  }

  input[type="number"] {
    appearance: textfield;
  }

  /* submit-btn */
  /* @media only screen and (min-width: 575px) {
    .submit-btn {
      padding-top: 26px;
    }
  } */