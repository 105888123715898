/* Breadcrumb style start */
.breadcrumb-container{
    padding: 0rem 0.5rem 0rem 0rem;
    height: 3.4375rem;
    display: flex;
    align-items: center;
    background: #FFF;
}
/* Breadcrumb style end */
/* Tab Pane Label Style start */
.tabpane-container{
    background: #FFF;
    /* padding: 0rem 1rem 0rem 1rem; */
    height: 2.8125rem;
    display: flex;
    align-items: center;
    margin-bottom: 0rem;
}

.tabpane-container .content{
    padding-right: 0.63rem;
}

.tabpane-item{
    border-radius: 0.3125rem;
    padding: 1rem 2rem;
    height: 2.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #3F3F3F !important;
    background: #eeeeee !important;
}

.tabpane-item.active{
    color: #3F3F3F  !important;
    background:#e3b075 !important;
    filter: drop-shadow(0px 0px 1px grey)
}

.tabpane-extra-btns{
    height: 2.5rem;
    border-radius: 0.3125rem;
    padding: 0.63rem 0.81rem;
    background: #88D0FF;
    font-size: 0.8125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;
}

.details-icon{
    width: 1.75rem;
    height: 1.625rem;
}

.passport-icon{
    width: 1.75rem;
    height: 1.75rem;
}

.medical-icon{
    width: 1rem;
    height: 1.25rem;
}

.appoinment-icon{
    width: 1.375rem;
    height: 1.375rem;
}
/* Tab Pane Label Style end */
/* Tab Pane Content style start */
/* .pagebody-container{} */

.tabcontent-container{
    padding: 0rem 2rem 3.13rem 2rem;
}

.page-title-label{
    color: #5D5D5D;
    font-size: 1.375rem;
    font-weight: 600;
    padding: 3.13rem 0rem;
}

.left-split-container{
    border-right: 0.0625rem solid #D5D5D5;
}

.form-cancel-btn{
    width: 7.38638rem;
    height: 2.5183rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    border: 2px solid #154674;
    color: #154674;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.form-cancel-btn:hover, .form-cancel-btn:focus{
    color: #FFF;
    background-color: #154674;
}

.form-next-btn{
    width: 7.38638rem;
    height: 2.5183rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    color: #FFF;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #154674;
}

.form-next-btn:hover, .form-next-btn:focus{
    color: #154674;
    border: 2px solid #154674;
    background-color: #FFF;
}

.form-previous-btn{
    width: 7.38638rem;
    height: 2.5183rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    color: #FFF;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #414141;
}

.form-previous-btn:hover, .form-previous-btn:focus{
    color: #414141;
    border: 2px solid #414141;
}
/* Tab Pane Content style end */
/* Basic Detail Page Style start */
.calendar-popup-container{
    margin-left: -7.5rem;
    margin-top: -0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    width: auto;
    overflow: hidden;
}

.adm-input-timepicker{
    width:100% !important;
    height: 2.09863rem;
    border: 1px solid #D0D0D0 !important;
    border-radius: 0.3125rem !important;
    background: #FFF;
    font-size: 0.83944rem;
    font-weight: 400;
    line-height: normal;
    text-align: center; 
}

.search-icon-img{
    width: 1.8125rem;
    aspect-ratio: 1/1;
}

.input-text-control.uin{
    margin-left: 0.8rem !important;
}

.age-form-container1{
    padding: 0rem 0rem 0rem 0.75rem !important;
}
.age-form-container2{
    padding: 0rem 0.375rem 0rem 0.375rem !important;
}
.age-form-container3{
    padding: 0rem 0.75rem 0rem 0rem !important;
}
/* Basic Detail Page Style end */
/* Document Upload Card Component Style start */
.document-upload-container{
    border-radius: 0.3125rem;
    background: #e1e1e1;
    padding: 0.63rem 0.63rem 0.63rem 0rem;
    margin: 0rem 0rem 0rem 0rem !important;
}

.document-add-btn{
    width: 5.3125rem;
    height: 2.1875rem;
    background: #154674;
    border-radius: 5px !important;
    cursor: pointer;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.document-add-btn.btn{
    --bs-btn-font-size: 0.875rem;
    --bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;
    --bs-btn-color: #154674;
    --bs-btn-bg: #9d9d9d;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: #154674;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: #154674;
}

.document-add-btn.btn:hover{
    color: #FFF !important;
    background-color: #272954 !important;
    border-color: #154674 !important;
}
/* Document Upload Card Component Style end */
/* Document Upload List Component Style start */
.upload-list-title{
    color: #5D5D5D;
    font-size: 0.65956rem;
    font-weight: 500;
    line-height: normal;    
}

.upload-list-container{
    border-radius: 0.3125rem;
    background: #F4F4F4;    
    padding: 0.63rem 0.63rem 0.63rem 0rem;
    margin: 0rem 0rem 0rem 0rem !important;
}

.upload-list-data{
    background: #fff;
    min-height: 2.5rem;
    padding: 0rem 0.88rem 0rem 0.88rem;
    margin-bottom: 0.63rem;
    display: flex;
    align-items: center;
    /* margin-top: 0.37rem; */
    border-radius: 0.3125rem;
}

.upload-list-data .text{
    color: #5D5D5D;
    font-size: 0.77948rem;
    font-weight: 400;
    line-height: normal;
}

.upload-list-data .number{
    border-radius: 0.3125rem;
    border: 1px solid #154674;
    width: 1.25916rem;
    height: 1.25916rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #154674;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: normal;
}
/* Document Upload List Component Style end */
/* Treatment History List Component Style start */
.treatment-history-container{
    border: 1px solid #D0D0D0;
    border-radius: 0.3125rem;
    background: #E1E1E1;
    padding: 0.62rem 0.62rem 0.62rem 1.31rem;
}

.treatment-history-content{
    background: #FFF;
    border-radius: 0.3125rem;
    min-height: 14.375rem;
    padding: 0.63rem 0rem 0.63rem 0.23rem;
}

.treatment-history-content .content.col{
    background: #FFF;
    padding: 0rem 0rem 0.81rem 0.2rem; 
    flex: 0 0 auto !important;
    max-height: 13rem;
    width: 91.66666667% !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.treatment-history-content .buttons.col{
    padding-left: 0.5rem !important;
    padding-right: 0rem !important;
    display:flex-end;
    flex-direction: column;
    flex: 0 0 auto !important;
    width: 8.33333333% !important;
}

.treatment-history-data{
    background: #F4F4F4;
    width: auto;
    min-height: 2.5rem;
    padding: 0rem 0.88rem 0rem 0.88rem;
    margin-bottom: 0.63rem;
    display: flex;
    align-items: center;
    /* margin-top: 0.37rem; */
    border-radius: 0.3125rem;
}

.treatment-history-data.highlight{
    border-color: #154674;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
}

.treatment-history-data .text{
    padding-left: 2.06rem;
    color: #5D5D5D;
    font-size: 0.77948rem;
    font-weight: 400;
    flex-wrap: wrap;
    line-height: normal;
}

.treatment-history-data .number{
    border-radius: 0.3125rem;
    border: 1px solid #154674;
    min-width: 1.25916rem;
    height: 1.25916rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #154674;
    font-size: 0.5996rem;
    font-weight: 400;
    line-height: normal;
}

.treatment-history-input{
    padding: 0.2rem 0rem 0.2rem 0.88rem !important;
    border-radius: 0.3125rem;
    resize: none;
    background: #ECECEC;
    font-size: 0.77948rem;
    font-weight: 400;
    width: 100%;
    padding: .5em 0;
    border: none;
    outline: none;
}

.treatment-history-input::placeholder{
    color: #5D5D5D;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}

.treatment-remove-btn{
    width: 1.625rem;
    height: 1.625rem;
    background: #CA2020;
    border-radius: 0.3125rem !important;
    cursor: pointer;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.treatment-remove-btn.btn{
    --bs-btn-font-size: 2rem;
    --bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;
    --bs-btn-color: #CA2020;
    --bs-btn-bg: #9d9d9d;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: none;
    --bs-btn-border-radius: 0.3125rem;
    --bs-btn-hover-border-color: #CA2020;
}

.treatment-remove-btn.btn:hover{
    color: #FFF !important;
    background-color: #a30c0c !important;
    border-color: #CA2020 !important;
}

.treatment-add-btn{
    width: 1.625rem;
    height: 1.625rem;
    background: #266BBC;
    border-radius: 0.3125rem !important;
    cursor: pointer;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.treatment-add-btn.btn{
    --bs-btn-font-size: 1.25rem;
    --bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;
    --bs-btn-color: #266BBC;
    --bs-btn-bg: #9d9d9d;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: none;
    --bs-btn-border-radius: 0.3125rem;
    --bs-btn-hover-border-color: #266BBC;
}

.treatment-add-btn.btn:hover{
    color: #FFF !important;
    background-color: #0f4c91 !important;
    border-color: #266BBC !important;
}
/* Treatment History List Component Style end */
/* Make Appointment Page Style start */
.react-calendar.appointment-calender{
    width: auto !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    border: none;
    background: inherit;
}
.appointment-calender .react-calendar__navigation{
    margin-bottom: 0rem !important;
    border: 1px solid #D0D0D0;
    height: 2.1875rem;
    background: #FFF;
    border-radius: 0.3125rem;
}

.appointment-calender .react-calendar__viewContainer{
    border: 1px solid #D0D0D0;
    background: #FFF;
    border-radius: 0.3125rem;
}
/* Make Appointment Page Style end */