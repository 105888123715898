.total-no {
  font-size: 10px;
  color: rgb(97, 93, 93);
}
.details-ward button {
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.second-container {
  /* width: 100%; */
  box-sizing: border-box;
  height: 60px;
}
.second-container p {
  height: 100%;
  font-weight: bold;
}
.second-container button:first-child {
  color: rgb(61, 75, 232);
  border: 1px solid rgb(61, 75, 232);
  border-radius: 8px;

  /* padding: 5px; */
}
.second-container button:last-child {
  color: white;
  background: #834920cc;
  border-radius: 8px;
}
.total-bed {
  /* width: 265px; */
  /* width: 243px; */
  width: 17.2rem;
  /* height: 155px; */
  height: 130px;
  /* gap: 0px; */
  border: 1px solid rgb(12, 12, 12);
  border-radius: 10px 0px 0px 0px;
  opacity: 0px;
  box-shadow: 5px;
  border: 1px solid white;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  */
  box-shadow: 0px 0px 6px rgba(60, 59, 59, 0.1);
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
}
.head-bed {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: left;
}
/* .bed-list-sub button:first-child{
    border: 1px solid rgba(215, 53, 32, 0.852);
    color: rgba(215, 53, 32, 0.852);
    border-radius: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
} */

/* .bed-list-sub-last{
    color: white;
    border-radius: 7px;
    background-color: skyblue;
    padding-top: 5px;
    padding-bottom: 5px;
} */
.bed-list-sub-out {
  font-size: 12px;
}

.bed-list-sub {
  font-size: 12px;
  border: 1px solid rgba(215, 53, 32, 0.852);
  color: rgba(215, 53, 32, 0.852);
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.private-add-cancel {
  font-size: 11px;
  border: solid 1px blue;
  color: blue;
  border-radius: 5px;
}
.private-add-save {
  font-size: 11px;
  border: solid 1px blue;
  color: white;
  background-color: rgb(45, 45, 230);
  border-radius: 5px;
}
.modal-label {
  color: rgb(107, 104, 104);
  font-size: 11px;
}
.ward-detail-select,
.ward-detail-select option {
  font-size: 12px;
  color: rgb(49, 48, 48);
}
.selected-ward {
  /* border-bottom: 3px solid #E3B075; */
  text-decoration: underline;
  text-decoration-color: #e3b075;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}
.all-ward-name {
  cursor: pointer;
}

.ward-details {
  max-width: max(800px, 90vw);
  min-width: min(800px, 90vw);
}
.private-add-room {
  color: white;
  background: #834920cc;
  border-radius: 8px;
}
.ward-add-bed {
  color: white;
  background: #3573f9cc;
  border-radius: 8px;
}
.private-suit-container {
    box-sizing: border-box;
    height: 3rem
}
.private-suit-container p {
    height: 100%;
    font-weight: bold;
  }

  .private-suit-container button:first-child {
    /* color: rgb(61, 75, 232);
    border: 1px solid rgb(61, 75, 232);
    border-radius: 8px; */
    font-size: 11px;
    border: solid 1px blue;
    color: blue;
    border-radius: 5px;
  
    /* padding: 5px; */
  }
  .private-suit-container button:last-child {
    /* color: white;
    background: #834920cc;
    border-radius: 8px; */
    font-size: 11px;
    border: solid 1px blue;
    color: white;
    background-color: rgb(45, 45, 230);
    border-radius: 5px;
  }