/* Search Input Box Style start */
.search-input-container{
    border: 1px solid #D0D0D0;
    border-radius: 0.3125rem;
    background: #F1F1F1;
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    min-height: 1.875rem !important;
}

.search-input-container.highlight{
    border-color: #154674;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
}

.search-input-icon{
    display: flex;
    align-items: center;
    color: #818181 !important;
    margin-right: 0.75rem;
}

.search-input-box{
    height: 1.3625rem;
    width: 9.375rem;
    border-radius: 0.3125rem;
    background-color: #F1F1F1;
    font-size: 0.83944rem;
    padding: .5rem 0;
    border: none;
    outline: none;
}

.search-input-box::placeholder{
    color: #5D5D5D;
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: normal;
}


/* Multi Input Box Style end */
/* Patient Search Box Style start */

.patient-search-container{
    display: flex;
    align-items: center;
    height: 2.09863rem;
    background: #fff;
    border-radius: 0.3125rem;
    border: 1px solid #D0D0D0;
    padding-right: 1rem;
    width: 100%;
    position: relative;
}

/* .patient-search-container.highlight{
    border-color: #154674;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
} */

.patient-search-container.is-valid {
    border-color: #28a745;
    box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25) inset, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.patient-search-container.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.25) inset, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.patient-search-container input{
    font-size: 0.83944rem;
    border: none;
    outline: none;
    width: 100%;
    cursor: text;
}

.patient-search-container input::placeholder{
    color: #A5A5A5;
    font-size: 0.75rem;
    font-weight: 400;
}

.patient-search-popup{
    position: -webkit-sticky;
    position: sticky;
    /* margin-left: 2rem; */
    margin-top: -0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    /* width: 18rem; */
    max-width: 30%;
    overflow: hidden;
}

.patient-search-content{
    height: 19.5rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.patient-search-box{
    display: flex;
    align-items: center;
    /* justify-content: end; */
    padding-left: 0rem;
    padding-right: 0rem;
    width: 100%;
}

.patient-search-options{
    height: 4.375rem;
    border-radius: 0.3125rem;
    background: #EBEBEB;
    margin-bottom: 0.5rem;
}

.patient-search-options .basic-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.patient-search-options .basic-details .id{
    color: #108BE4;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
}

.patient-search-options .basic-details .name{
    color: #2C2C2C;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
}

.patient-search-options .mobile-number{
    display: flex;
    align-items: center;
    justify-content: end;
    color: #2C2C2C;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
}

/* Patient Search Box Style start */

.cancel-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #999;
    outline: none;
}

.cancel-button:hover {
    color: #333;
}