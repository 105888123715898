.container .labtest {
    max-width: 100%; 
    overflow-x: auto; 
  }
  
 .labtest table {
    width: 100%; 
    table-layout: fixed;  
  }
  
  .labtest th {
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
  }
  .labtest td {
    text-align: center; 
    padding: 3px; /* Adds spacing inside table cells */
    word-wrap: break-word; 
  }
  
  .labtest th:first-child, .labtest td:first-child {
    width: 4rem;
  }
  
  .labtest .custom_input1 {
    max-width: 50px; 
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee;  
    padding: 10px;
  }

  .labtest .custom_input {
    padding: 16px;
    height: 2.8125rem;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background-color: #eeeeee; 
    overflow: hidden; 
  }

  .labtest th:nth-child(2) , .labtest th:nth-child(4),.labtest th:nth-child(5), .labtest td:nth-child(2) ,.labtest td:nth-child(4), .labtest td:nth-child(5){
    width:25rem;
    min-width:25rem;
    max-width:25rem;
  }
  