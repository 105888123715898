.tab-btn button{
    font-size: 12px;
    /* padding-top: 10px;
    padding-bottom: 10px; */
}
.pr-detail-edit{
    border: 1px solid #154674;
    border-radius: 5px;
}
/* .icons-edit-div{
    width: 10px;
    height: 10px;
    background-color: aqua;
    object-fit: contain;
} */
/* font-size: 12px; */
/* .ward-addition button:first-child{
    font-size: 11px;
    border: solid 1px blue;
    color: blue;
    
}
.ward-addition button:last-child{
    font-size: 11px;
    border: solid 1px blue;
    background-color: blue;
    color: white;
} */

.suite-img2{
   background: #FFFAF4;
   border-radius: 8px; 
}
.suite-img3{
   /* background-color: rgb(251, 0, 255); */
   /* width: 13rem; */
}

.card-id-number{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    /* line-height: 18.75px; */
    line-height: 1.5rem;
    text-align: left;
    color: #0A2278;    
}

.card-room{
font-family: Roboto;
font-size: 16px;
font-weight: 500;
/* line-height: 18.75px; */
line-height: 1.5rem;
text-align: left;
color: #636363;

}

.card-floor{
font-family: Roboto;
font-size: 15px;
font-weight: 400;
/* line-height: 17.58px; */
line-height: 1.5rem;
text-align: left;
color: #636363;
margin-bottom: 1.5rem;

}

.card-patient-ID, .card-patient-name,.card-ip-number{
font-family: Roboto;
font-size: 12px;
font-weight: 400;
line-height: 14.06px;
line-height: .5rem;
text-align: left;
color: #636363;   
}
.card-right-div{
    /* margin-top: 1.5rem; */
}
.card-right-div button{

padding: 7px 0px 0px 0px;
background: #F8857D;


}
.card-right-div button p{
    font-family: Roboto;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.89px;
    text-align: left;
    color: #FFFFFF;
}

.card-Rete-per-ay{
    font-family: Roboto;
font-size: 12px;
font-weight: 300;
line-height: 14.06px;
text-align: right;
color: #636363;

}
.card-pay-number{
font-family: Roboto;
font-size: 17px;
font-weight: 700;
line-height: 19.92px;
text-align: right;
color: #636363;

}
.private-suit-container{
    border: 1px solid rgb(12, 12, 12);
    border-radius: 10px 0px 0px 0px;
    opacity: 0px;
    box-shadow: 5px;
    border: 1px solid white; 
    box-shadow: 0px 0px 6px rgba(60, 59, 59, 0.1); 
    background-color: #fff;
    border-radius: 8px; 
}

.private-suit-head{
    font-family: Roboto; 
}
.img-upload-suit {
    width: 10rem;
    height: 10rem;
    border: 2px dashed #ccc;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-weight: normal;
    color: #333;
    background-color: #f9f9f9;
}
.private-sute-edit{
    position: absolute;
    left: 7rem;
    top: 5px;
    width: 11rem;
    height: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    background-color: white;
    border-radius: 8px;
}
.showEditdiv{
    cursor: pointer;
}
.private-sute-edit div{
    height: 30%;
    background: #F1F1F1;
    cursor: pointer;
    border-radius: 5px;
}
.edit-icon{
    width: 1.3rem;
    height: 1.3rem;
}
.delete-red img{
    width: 1.3rem;
    height: 1.3rem;
}
.edit-icon p{
    color:#0A2278 ;
}
.delete-red p{
    color:red ;
}
.detail-icon p{
    color:#5C09E1;
}

/* background: #5C09E1; */

/* .private-sute-edit p{
    color:#0A2278 ;

} */

/* .private-sute-edit p:nth-child(1){
    color:#0A2278 ; 
} */