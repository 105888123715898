/* Multi Input Box Style start */
.multi-input-container{
    border: 1px solid #D0D0D0;
    border-radius: 0.3125rem;
    background: #FFF;
    padding: 0rem 0rem 0rem 0.7rem;
    min-height: 2.09863rem !important;
}

.multi-input-container.highlight{
    border-color: #154674;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
}

.multi-input-content{
    padding: 0.31rem 0rem 0.31rem 0rem;
    height: 5.25rem;
    /* width: 100%; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    overflow-y: scroll;
    overflow-x: hidden;
}

.multi-input-item{
    background: #D8FFE3;
    min-width: 20.33%;
    height: 1.499rem;
    padding: 0.37rem 0.13rem 0.37rem 0.75rem ;
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
}

.multi-input-box .close-btn{
    width: fit-content;
    flex: 0 0;
}

.multi-input-item .close{
    width: 1.25rem;
    height: 1.25rem;
    background: #6E6E6E;
    transform: rotate(-45deg);
    color: #fff;
    font-size: 0.83944rem;
    font-weight: 300;
    line-height: normal;
    border-radius: 0.78125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0rem;
    cursor: pointer;
}

.multi-input-item .text{
    padding-right: 2.06rem !important;
    color: #5D5D5D;
    font-size: 0.65956rem;
    font-weight: 400;
    line-height: normal;
}

.multi-input-box{
    height: 1.5625rem;
    border-radius: 0.3125rem;
    flex-grow: 1;
    font-size: 0.83944rem;
    padding: .5em 0;
    border: none;
    outline: none;
}

.multi-input-box::placeholder{
    color: #5D5D5D;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}


/* Multi Input Box Style end */