.physician-list-card-container{
    height: 9.25rem;
    border-radius: 0.3125rem;
    background: #FFF;
    padding: 0.5rem;
}

.physician-img-container{
    height: 8.19rem;
    border-radius: 0.3125rem;
    /* background-color: red; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.physician-profile-image{
    object-fit: fill;
    height: 100%;
    border-radius: 50%;
}

.physician-list-id{
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
}

.physician-list-id .label{
    color: #232323;
}

.physician-list-id .text{
    color: #209FD6;
}

.physician-list-name{
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    color: #333;
}

.physician-list-qualification{
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    color: #232323;
}

.physician-list-speciality{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;
    color: #575757;
    font-size: .75rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.physician-new-box{
    border: 3px solid #FFF;
    border-radius:10px;
    background-image: linear-gradient(to right, #FFDBB0,#FFF4E6);
}

.physician-new-box1{
    border: 3px solid #ffeed9;
    border-radius:5px;
    background-color: #ffeed9
}

.pro-image-cont{
    width: 4.063rem;
    height: 4.063rem;
}

.edit-button-1{
    background-color: #f5f6ff;
    cursor: pointer;
}

.remove-button-1{
    background-color: #FFF5F5;
    cursor: pointer;
}
