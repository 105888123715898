.buttonviewdown{
    width: 6rem;
    height: 2.6rem;
    border-radius: .5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#fafafa;
    color: #1F5EA7;
    border: 2px solid #1F5EA7;
    font-size: medium;
    font-weight: 400;
    margin-right: .5rem;
  }
  
  .buttonviewdown:hover{
    color: #fafafa;
    background-color:#1F5EA7;
    border: 2px solid #1F5EA7;
  }


  .bed-card {
    position: relative;
  }
  
  .selected-bed {
    border: 2px solid blue;
  }

  .available-bed{
    background-color: #F6FFF6;
    border:2px solid #35F983;
    color: #35F983;
  }

  
  .reserved-bed{
    background-color: #FFFBED;
    border:2px solid #FFE071;
    color: #FFE071;
  }

       
  .occupied-bed{
    background-color: #FFFBF8;
    border:2px solid #FFB26B;
    color: #FFB26B;
  }

  .move-icon{
    position: absolute;
    top: -23px;
    left: -23px;
  }

  .primary-bg{
    color: #E3B075;
    background-color: #fafafa;
    border: 2px solid #E3B075;
  }

  .primary-bg.active{
    color: #3f3f3f;
    background-color: #E3B075;
    border: 2px solid #E3B075;
  }